import { defineStore } from 'pinia'

// collection roles
export const ROLE_ADMIN = 'Admin'
export const ROLE_OWNER = 'Owner'
export const ROLE_EDITOR = 'Editor'
export const ROLE_TRANSCRIBER = 'Transcriber'
export const ROLE_MANUAL_TRANSCRIBER = 'ManualTranscriber'
export const ROLE_VIEWER = 'Reader'

// site roles
export const ROLE_SITE_OWNER = 'Owner'
export const ROLE_SITE_CONTRIBUTOR = 'Editor'

interface UserManagement {
  viewUsers: boolean
  viewInvitations: boolean
  inviteUsers: boolean
  removeUsers: boolean
  editRoles: boolean
  setRoleOwner: boolean
  removeOwner: boolean
}

interface CollectionManagement {
  viewMetadata: boolean
  editMetadata: boolean
  viewActivity: boolean
  viewRecycleBin: boolean
  deleteCollection: boolean
}

interface DocManagement {
  viewMetadata: boolean
  editMetadata: boolean
  upload: boolean
  export: boolean
  copy: boolean
  merge: boolean
  link: boolean
  unlink: boolean
  move: boolean
  delete: boolean
  restore: boolean
  permanentDelete: boolean
  importText: boolean
}

interface PageManagement {
  view: boolean
  upload: boolean
  move: boolean
  delete: boolean
  export: boolean
  viewMetadata: boolean
  setInProgressOrDone: boolean
  setFinalOrGT: boolean
  edit: boolean
  viewVersion: boolean
  deleteVersion: boolean
  hideOnSites: boolean
}

interface ModelManagement {
  recognise: boolean
  train: boolean
}

interface TagManagement {
  viewTags: boolean
  createTags: boolean
  editTags: boolean
  deleteTags: boolean
}

interface CreditManagement {
  viewUsage: boolean
  useCredits: boolean
  transferCredits: boolean
}

interface SitesManagement {
  delete: boolean
  viewSettings: boolean
  manageUsers: boolean
  addPage: boolean
  inviteEditor: boolean
  inviteOwner: boolean
}

interface Permissions {
  users?: UserManagement
  collections?: Object
  tags?: TagManagement
  credits?: CreditManagement
  documents?: Object
  doc?: DocManagement
  pages?: PageManagement
  models?: ModelManagement
  teams?: Object
}

interface Roles {
  isAdmin: boolean
  isOwner: boolean
  isEditor: boolean
  isTranscriber: boolean
  isViewer: boolean
  isManualTranscriber: boolean
}

interface SiteRoles {
  isAdmin: boolean
  isOwner: boolean
  isContributor: boolean
}

export const usePermissions = defineStore('permissions', () => {
  const collectionsApi = useCollections()
  const { userProfile } = useKeycloak()
  const myRole = ref<Roles>()
  const mySiteRole = ref<SiteRoles>()
  const users = ref<UserManagement>()
  const collections = ref<Object>({})
  const tags = ref<TagManagement>()
  const credits = ref<CreditManagement>()
  const documents = ref<Object>({})
  const doc = ref<DocManagement>()
  const pages = ref<PageManagement>()
  const models = ref<ModelManagement>()
  const collectionMeta = ref<CollectionMetadata>()
  const sites = ref<SitesManagement>({})
  const teams = ref<Object>({})

  const hasRole = (...roles: boolean[]) => roles.some(Boolean)

  const setPermissions = (role: string) => {
    const isAdmin = role === ROLE_ADMIN || userProfile.IsAdmin
    const isOwner = role === ROLE_OWNER
    const isEditor = role === ROLE_EDITOR
    const isTranscriber = role === ROLE_TRANSCRIBER
    const isViewer = role === ROLE_VIEWER
    const isManualTranscriber = role === ROLE_MANUAL_TRANSCRIBER

    myRole.value = {
      isAdmin,
      isOwner,
      isEditor,
      isTranscriber,
      isViewer,
      isManualTranscriber,
    }

    users.value = {
      viewUsers: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber,
        isViewer
      ),
      viewInvitations: hasRole(isAdmin, isOwner, isEditor),
      inviteUsers: hasRole(isAdmin, isOwner, isEditor),
      removeUsers: hasRole(isAdmin, isOwner, isEditor), // viewers, transcribers, and manual transcribers can only remove themselves
      editRoles: hasRole(isAdmin, isOwner, isEditor),
      setRoleOwner: hasRole(isAdmin, isOwner),
      removeOwner: hasRole(isAdmin, isOwner),
    }

    tags.value = {
      viewTags: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber,
        isViewer
      ),
      createTags: hasRole(isAdmin, isOwner, isEditor),
      editTags: hasRole(isAdmin, isOwner, isEditor),
      deleteTags: hasRole(isAdmin, isOwner, isEditor),
    }

    credits.value = {
      viewUsage: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber
      ),
      useCredits: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber
      ),
      transferCredits: hasRole(isAdmin, isOwner, isEditor),
    }

    doc.value = {
      viewMetadata: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber,
        isViewer
      ),
      editMetadata: hasRole(isAdmin, isOwner, isEditor),
      upload: hasRole(isAdmin, isOwner, isEditor),
      export: hasRole(isAdmin, isOwner, isEditor),
      copy: hasRole(isAdmin, isOwner, isEditor),
      merge: hasRole(isAdmin, isOwner, isEditor),
      link: hasRole(isAdmin, isOwner, isEditor), // viewers, transcribers, and manual transcribers can only link a document if they are the uploader
      unlink: hasRole(isAdmin, isOwner), // viewers, transcridbers, and manual transcribers can only unlink a document if they are the uploader
      move: hasRole(isAdmin, isOwner), // viewers, transcribers, and manual transcribers can only move a document if they are the uploader
      delete: hasRole(isAdmin, isOwner), // viewers, transcribers, and manual transcribers can only delete a document if they are the uploader
      restore: hasRole(isAdmin, isOwner, isEditor),
      permanentDelete: hasRole(isAdmin, isOwner), // viewers, transcribers, and manual transcribers can only permanently delete a document if they are the uploader
      importText: hasRole(isAdmin, isOwner, isEditor),
    }

    pages.value = {
      view: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber,
        isViewer
      ),
      upload: hasRole(isAdmin, isOwner, isEditor),
      move: hasRole(isAdmin, isOwner, isEditor),
      delete: hasRole(isAdmin, isOwner, isEditor),
      export: hasRole(isAdmin, isOwner, isEditor),
      viewMetadata: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber,
        isViewer
      ),
      setInProgressOrDone: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber
      ),
      setFinalOrGT: hasRole(isAdmin, isOwner, isEditor),
      edit: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber
      ),
      viewVersion: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber,
        isViewer
      ),
      deleteVersion: hasRole(isAdmin, isOwner, isEditor),
      hideOnSites: hasRole(isAdmin, isOwner, isEditor),
    }

    models.value = {
      recognise: hasRole(isAdmin, isOwner, isEditor, isTranscriber),
      train: hasRole(isAdmin, isOwner, isEditor),
    }
  }

  // fetches the permissions of ONE collection
  async function getCollectionPermissions(id: number) {
    if (!id) return
    if (
      collectionMeta.value === undefined ||
      !collectionMeta.value ||
      collectionMeta.value.colId !== id
    ) {
      if (collections.value === undefined) {
        collections.value = {}
      }
      try {
        collectionMeta.value = await collectionsApi.fetchCollectionMeta({
          collId: id,
        })
      } catch (e) {
        console.error('Failed to fetch collection permissions:', e)
      }
    }

    const role = collectionMeta.value?.role
    setPermissions(role)
    setCollectionPermissions(id, role)
  }

  function setCollectionPermissions(id: number, role: string) {
    const isAdmin = role === ROLE_ADMIN || userProfile.IsAdmin
    const isOwner = role === ROLE_OWNER
    const isEditor = role === ROLE_EDITOR
    const isTranscriber = role === ROLE_TRANSCRIBER
    const isViewer = role === ROLE_VIEWER
    const isManualTranscriber = role === ROLE_MANUAL_TRANSCRIBER
    collections.value[id] = {
      viewMetadata: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber,
        isViewer
      ),
      role: role,
      editMetadata: hasRole(isAdmin, isOwner, isEditor),
      viewActivity: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber,
        isViewer
      ),
      viewRecycleBin: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber,
        isViewer
      ),
      deleteCollection: hasRole(isAdmin, isOwner),
    }
  }

  // returns the permissions of a collection list
  function setCollectionPermissionsList(collectionList: Collection[]) {
    if (!collectionList) return
    collections.value = {}
    collectionList.forEach(collection => {
      setCollectionPermissions(collection.colId, collection.role)
    })
  }

  function setDocumentPermissions(document: Document) {
    if (!document) return
    const isAdmin = myRole.value.isAdmin
    const isOwner = myRole.value.isOwner
    const isEditor = myRole.value.isEditor
    const isTranscriber = myRole.value.isTranscriber
    const isViewer = myRole.value.isViewer
    const isManualTranscriber = myRole.value.isManualTranscriber
    const isUploader = document.uploaderId === userProfile.UserId
    documents.value[document.docId] = {
      viewMetadata: hasRole(
        isAdmin,
        isOwner,
        isEditor,
        isTranscriber,
        isManualTranscriber,
        isViewer
      ),
      editMetadata: hasRole(isAdmin, isOwner, isEditor),
      upload: hasRole(isAdmin, isOwner, isEditor),
      export: hasRole(isAdmin, isOwner, isEditor),
      copy: hasRole(isAdmin, isOwner, isEditor),
      merge: hasRole(isAdmin, isOwner, isEditor),
      link: hasRole(isAdmin, isOwner, isEditor, isUploader),
      unlink: hasRole(isAdmin, isOwner, isUploader),
      move: hasRole(isAdmin, isOwner, isUploader),
      delete: hasRole(isAdmin, isOwner, isUploader),
      permanentDelete: hasRole(isAdmin, isOwner, isUploader),
      restore: hasRole(isAdmin, isOwner, isEditor),
      importText: hasRole(isAdmin, isOwner, isEditor),
    }
  }

  // returns the permissions of a document list
  async function setDocumentPermissionsList(documentList: Document[]) {
    if (!documentList) return
    documents.value = {}
    documentList.forEach(document => {
      setDocumentPermissions(document)
    })
  }

  // set the sites permissions
  function setSitesPermissions(sites: Site[]) {
    if (!sites) return
    sites.value = {}
    sites.forEach(site => {
      setSitePermissions(site)
    })
  }

  function setSitePermissions(site: Site) {
    if (!site) return
    const isAdmin = userProfile.IsAdmin
    const isOwner = site.userId === userProfile.UserId
    const isContributor = site.userId !== userProfile.UserId

    mySiteRole.value = {
      isAdmin,
      isOwner,
      isContributor,
    }

    sites.value[site.id] = {
      delete: hasRole(isAdmin, isOwner),
      viewSettings: hasRole(isAdmin, isOwner),
      addPage: hasRole(isAdmin, isOwner),
      manageUsers: hasRole(isAdmin, isOwner),
      manageInvitations: hasRole(isAdmin, isOwner, isContributor),
    }
  }

  function setTeamPermissions(team: Team) {
    if (!team) return
    const isAdmin =
      userProfile.IsAdmin ||
      parseInt(team.adminId) === parseInt(userProfile.UserId)
    const isManager = team.managerIds.includes(userProfile.UserId)
    const isMember = team.userIDs.includes(userProfile.UserId)
    console.log(
      userProfile.UserId,
      'isAdmin',
      isAdmin,
      'isManager',
      isManager,
      'isMember',
      isMember
    )
    teams.value[team.id] = {
      view: hasRole(isAdmin, isManager),
      edit: hasRole(isAdmin, isManager),
      viewMembers: hasRole(isAdmin, isManager, isMember),
      viewInvitations: hasRole(isAdmin, isManager, isMember),
      inviteMembers: hasRole(isAdmin, isManager),
      inviteAdmins: hasRole(isAdmin),
      removeMembers: hasRole(isAdmin, isManager),
      setRole: hasRole(isAdmin, isManager),
      makeAdmin: hasRole(isAdmin),
      removeAdmin: hasRole(isAdmin),
    }
  }

  return {
    myRole,
    mySiteRole,
    users,
    collections,
    tags,
    credits,
    documents,
    doc,
    pages,
    models,
    sites,
    teams,
    getCollectionPermissions,
    setCollectionPermissionsList,
    setDocumentPermissions,
    setDocumentPermissionsList,
    setSitesPermissions,
    setSitePermissions,
    setTeamPermissions,
  }
})
